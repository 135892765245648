import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/usr/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/usr/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/app/src/app/[locale]/StoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/app/src/components/AuthCheck.tsx");
;
import(/* webpackMode: "eager" */ "/usr/app/src/scss/main.scss");
;
import(/* webpackMode: "eager" */ "/usr/app/src/index.scss");
